import '../css/common.css';
import $ from 'jquery';
import EQCSS from 'eqcss/EQCSS';

import { LocalizationManager } from 'van-beek-framework/core';

$(document).ready(() => {
    var $sidebar = $('.configurator-sidebar');
    var $contentWrapper = $('.configurator-content__wrapper');
    var $collapseButton = $sidebar.find(".floating-button[data-action='collapse']");
    let $languageSwitcher = $sidebar.find(".language-switcher");
    let languageSwitcherActive = false;
    let currentLanguage = LocalizationManager.shared.language;

    $languageSwitcher.find(`a[data-language='${currentLanguage}']`).attr("data-current-language", true).prependTo($languageSwitcher);
    $languageSwitcher.attr("data-initialized", true);

    $collapseButton.on("click", () => {
        $sidebar.toggleClass("collapsed");
        $contentWrapper.toggleClass("fullscreen");
        setCookie("sidebar-collapsed", $sidebar.hasClass("collapsed"), 30);
        
        setTimeout(() => {
            EQCSS.apply();
        }, 300);
    });

    $languageSwitcher.on("click", () => {
        languageSwitcherActive = !languageSwitcherActive;
        if (languageSwitcherActive) {
            $languageSwitcher.addClass("active");
        } else {
            $languageSwitcher.removeClass("active");
        }
    });

    $languageSwitcher.on("click", "a", function() {
        if (!languageSwitcherActive) {
            return;
        }

        let language = $(this).attr("data-language");
        if (language !== currentLanguage) {
            window.vbfSetLanguage(language);
        }
    });
});

function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }

    document.cookie = name + "=" + (value || "")  + expires + "; path=/; SameSite=Strict";
}